// React
import React from 'react'
import PropTypes from 'prop-types'

// 
import { Link } from 'gatsby'

// Styles
import "./TableOfContents.scss"

// 
import Slugger from 'github-slugger'
const slugger = new Slugger();


// Table of contents component
const TableOfContents = ({
    title,
    headings,
    pathname,
    showH3 = true,
    isPage = false,
    ...rest
}) => {
    // Reset slugger (necessary to avoid it adding -1, -2, etc. at the end of the references due to multiple loading during pageload I think)
    slugger.reset();

    // Transform the headings data structure
    // so that I can render it as a nested list
    let toc = [];
    const buildToc = () => {
        let lastTwoIndex = 1;
        let lastThreeIndex = 1;

        headings.forEach(heading => {

            if (heading.depth === 2) {
                toc.push({
                    number: lastTwoIndex,
                    value: heading.value,
                    depth: heading.depth,
                    subheadings: [],
                });
                lastTwoIndex++;
                lastThreeIndex = 1;
            }
            else if (showH3 && heading.depth === 3) {
                toc[ lastTwoIndex - 2 ].subheadings.push({
                    number: lastTwoIndex + "." + lastThreeIndex,
                    value: heading.value,
                    depth: heading.depth
                });
            }
        });
    };
    buildToc();

    // Render
    return (
        <aside {...rest}>
            <div className="toc-top">
                {isPage
                ? <>On this page</>
                : <>In this article</>
                }
            </div>
            <div className="toc-heading">
                {title}
            </div>
            <ol>
                {toc.map(heading => (
                    <li key={heading.value}>
                        <Link
                            to={`${pathname}/#${slugger.slug(heading.value)}`}
                        >
                            {heading.value}
                        </Link>
                        {heading.subheadings && (
                            <ol>
                                {heading.subheadings.map(subheading => (
                                    <li key={subheading.value}>
                                        <Link
                                            to={`${pathname}/#${slugger.slug(subheading.value)}`}
                                        >
                                            {subheading.value}
                                        </Link>
                                    </li>
                                ))}
                            </ol>
                        )}
                    </li>
                ))}
            </ol>
        </aside>
    )
}

TableOfContents.propTypes = {
    headings: PropTypes.array.isRequired,
}

export default TableOfContents
