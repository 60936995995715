// React
import React from "react"

// Gatsby
import {
    Link,
    graphql
} from "gatsby"

// Images
import Image from "gatsby-image"

// MDX
import MdxWrapper from "../components/mdx/MdxWrapper"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Page components
import TableOfContents from '../components/TableOfContents';

// Styles
import "../styles/pages/DownloadsPage.scss"

// Component for the downloads page
const DownloadsPage = ({
    data,
    location
}) => {

    // MDX data for this page
    const pageData = data.mdx;

    // Frontmatter for this page
    const {
        title: pageTitle,
        category: pageCategory,
        // TODO: Replace this date in the md file with an automatic last updated date feature based on the file changed attribute ?
        date: pageDate,
        description: pageDescription,
    } = pageData.frontmatter;

    // Page thumbnail
    let pageThumbnail = data.pageIllustration.childImageSharp.fluid;
    //let shareThumbnail = data.pageIllustration.childImageSharp.resize?.src;

    // Page headings for ToC
    const pageHeadings = pageData?.headings;

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
                // thumbnail={shareThumbnail}                
            />

            {/* Page */}
            <article id="downloads-page">

                {/* Page header */}
                <header className="page-header page-header-with-thumbnail">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                    {/* Image on rhs */}
                    <section className="page-image">
                        {pageThumbnail &&
                            <Image fluid={pageThumbnail} />
                        }
                    </section>

                    {/* Buttons on top-right */}     
                    <aside className="page-buttons">
                        <Link to={'resources'}>
                            Resources
                        </Link>
                    </aside>

                </header>
                    
                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* MDX */}
                        <MdxWrapper>
                            {pageData.body}
                        </MdxWrapper>

                    </section>

                    {/* Table of contents */}
                    {pageHeadings &&
                        <TableOfContents
                            className="page-toc"
                            title={pageTitle}
                            headings={pageHeadings}
                            pathname={location.pathname}
                            showH3={false}
                            isPage={true}
                        />
                    }

                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    Last updated: {pageDate}

                </footer>
                
            </article>

        </Layout>
    )
}

// Export component
export default DownloadsPage

// GraphQL query
export const pageQuery = graphql`

    query DownloadsPageQuery {

        pageIllustration: file(
            absolutePath: { 
                regex: "/homepage-illustration-downloads.png/"
            }
        ) {
            childImageSharp {
                fluid( maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
                resize(width: 1200, height: 1200) {
                    src
                }                
            }
        }         

        mdx(
            fileAbsolutePath: {
                glob: "**/content/pages/**/downloads.mdx"
            }
        ) {
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                category
                date(formatString: "MMM D, YYYY")
                description
            }
            headings {
                depth,
                value
            }        
        }

    }
`
