// React
import React from 'react'
//import PropTypes from 'prop-types'

// Post card component
// Is used to display a YouTube embedd video player in mdx blog posts
const YoutubeVideo = ({
    id,
    ...rest
}) => (
    // See https://www.ankursheel.com/blog/full-width-you-tube-video-embed
    <div
        style={{
            margin: '2rem 0',
            padding: '2rem',
            backgroundColor: '#333',
            textAlign: 'center',
            borderRadius: '5px',
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%',
        }}
    >
        <iframe
            id="ytplayer"
            title="ytplayer"
            type="text/html"
            src={`https://www.youtube.com/embed/${id}?modestbranding=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
            }}
        >
        </iframe>
    </div>
);

// Prop types
YoutubeVideo.propTypes = {
    id: function(props, propName, componentName) {
        if (typeof props[propName] !== 'string' || props[propName].length !== 11) {
            return new Error(
                'The prop `' + propName + '` supplied to' +
                ' `' + componentName + '` is not a valid YouTube video id. It must be a string of 11 characters in length.'
            );
        }
    },
}

// Export
export default YoutubeVideo;

