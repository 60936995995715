// React
import React from "react"

// MDX
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from "gatsby-plugin-mdx"

// Import custom components
import YoutubeVideo from "./YoutubeVideo";
// import copyToClipboard from './unused/CopyToClipboard'

// Copy code component
// The problem is that I don't actually have access to the string I want to copy to the clipboard. At this stage, when I get the prop, it's already broken up into an array of 100s of tokens :(
// const CodeBlock = (props) => {

//     const handleClick = () => {
//         console.log(props);
//         copyToClipboard();
//     }

//     return (
//         <div>
//             <button onClick={handleClick}>Copy to clipboard</button>
//             <pre style={{ color: 'tomato' }} {...props} />
//         </div>
//     );
// }

// Use custom components
const components = {
    //h2: props => <div {...props}>Testing 123</div>,
    //pre: CodeBlock,
    YoutubeVideo,
}

// MDXWrapper component
export default ({ children }) => (
    <MDXProvider components={components}>
        <div className="mdx-renderer">
            <MDXRenderer>
                {children}
            </MDXRenderer>
        </div>
    </MDXProvider>
);